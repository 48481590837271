import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { buildUrl } from "../../../services";

/**
 * @param { {
 *   id: number,
 *   fecha: string,
 *   cliente: string,
 *   cliente_id: string,
 *   cedula: string,
 *   medida_inicial: string,
 *   medida_final: string,
 *   total_medida: string,
 *   contador_id: string,
 *   firma?: string,
 *   plaza: string,
 *   plaza_id: string,
 *   usuario: string,
 *   unidad_medida: string,
 * } props
 *
 */
function conduce(props) {
  const param = props;
  const splitTextAndGetHeight = (text, size) => {
    var lines = doc.splitTextToSize(text, size);
    return {
      text: lines,
      height: doc.getTextDimensions(lines).h,
    };
  };

  const empresa = {
    rnc: "101033738",
    company: "Propano y Derivados S.A.",
    telefono: "809-364-1000 Opción 1",
    web: "www.grupopropagas.com",
    email: "servicioalcliente@propagas.do",
  };

  const options = {
    format: [100, 250],
  };

  var doc = new jsPDF(options);
  //autoTable(doc, { html: '#tabla-conduce' })

  var docWidth = doc.internal.pageSize.width;
  //var docHeight = doc.internal.pageSize.height;

  var colorBlack = "#000000";
  //var colorGray = "#4d4e53";
  //starting at 15mm
  var currentHeight = 15;

  var marginX = 2;
  var marginY = doc.internal.pageSize.width - 30;

  //var startPointRectPanel1 = currentHeight + 6;

  var pdfConfig = {
    headerTextSize: 16,
    subHeaderTextSize: 12,
    labelTextSize: 11,
    fieldTextSize: 9,
    lineHeight: 6,
    subLineHeight: 4,
  };

  doc.setFontSize(pdfConfig.headerTextSize);
  doc.setFont("times", "bold");
  doc.setTextColor(colorBlack);

  doc.text(docWidth / 6, currentHeight, empresa.company || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFont("times", "normal");
  doc.setFontSize(pdfConfig.labelTextSize);
  doc.text(docWidth / 4 + 10, currentHeight, "RNC: " + empresa.rnc || "");
  currentHeight += pdfConfig.subLineHeight;
  doc.text(docWidth / 4, currentHeight, "Teléfono: " + empresa.telefono || "");
  currentHeight += pdfConfig.subLineHeight;
  doc.text(docWidth / 4 + 2, currentHeight, "Web: " + empresa.web || "");
  currentHeight += pdfConfig.subLineHeight;
  doc.text(docWidth / 4, currentHeight, "Email: " + empresa.email || "");
  currentHeight += pdfConfig.subLineHeight;

  doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFont("times", "bold");
  doc.text("Conduce", docWidth / 4 + 20, currentHeight, {
    align: "center",
    lang: "es",
  });

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFont("times", "normal");
  doc.setFontSize(pdfConfig.labelTextSize);
  doc.text(
    marginX,
    currentHeight,
    "Documento: " +
      "PROG-" +
      param.cliente.cliente_id +
      "-" +
      param.id.toString() || ""
  );
  currentHeight += pdfConfig.subLineHeight;
  doc.text(marginX, currentHeight, "Fecha: " + new Date().toLocaleDateString());
  currentHeight += pdfConfig.subLineHeight;

  currentHeight += pdfConfig.subLineHeight;

  const colums = ["Medidor", "Inicio", "Fín", "Diferencia"];
  const rows = [
    param.contador_id || "",
    param.medida_inicial || "",
    param.medida_final || "",
    param.total_medida || "",
  ];

  const tableColWidth = [0.5, 0.5, 0.5, 0.5];
  const headerStyles = {
    fillColor: [240, 240, 240],
    textColor: [0],
    fontFamily: "helvetica",
    fontStyle: "bold",
  };

  doc.autoTable({
    head: [colums],
    body: [rows],
    startY: currentHeight,
    headStyles: {
      fillColor: headerStyles.fillColor,
      textColor: headerStyles.textColor,
      fontStyle: headerStyles.fontStyle,
      fontSize: 10,
      halign: "center",
      border: true,
      lineColor: 10,
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { cellWith: tableColWidth[0] },
      1: { cellWith: tableColWidth[1] },
      2: { cellWith: tableColWidth[2] },
      3: { cellWith: tableColWidth[3] },
    },
    bodyStyles: {
      fontSize: 8, // Adjust the font size for the body
      font: "helvetica", // Set the font family for the body
      cellPadding: { top: 1, right: 2, bottom: 1, left: 2 }, // Adjust cell padding
      textColor: [0, 0, 0], // Set text color for the body
      rowPageBreak: "avoid", // Avoid row page breaks
      border: true,
      lineColor: 10,
      lineWidth: 0.5,
    },
  });

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFontSize(pdfConfig.fieldTextSize);
  doc.setFont("times", "bold");
  doc.text(marginX, currentHeight, "Producto:");
  doc.text(marginY, currentHeight, "GLP(E)");
  currentHeight += pdfConfig.subLineHeight;

  if (param.unidad_medida === "M3") {
    doc.text(marginX, currentHeight, "Cantidad: (M3)");
    doc.text(marginY, currentHeight, param.total_medida || "");
    currentHeight += pdfConfig.subLineHeight;
    doc.text(marginX, currentHeight, "Total Galones: ");
    doc.text(
      marginY,
      currentHeight,
      parseFloat(param.total_medida * 1.2)
        .toFixed(2)
        .toString() || ""
    );
  } else {
    doc.text(marginX, currentHeight, "Total Galones:");
    doc.text(marginY, currentHeight, param.total_medida || "");
  }

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

  doc.setFont("times", "normal");
  doc.text(
    marginX,
    currentHeight,
    "Plaza: " + param.plaza.plaza_id + " " + param.plaza.plaza || ""
  );
  currentHeight += pdfConfig.subLineHeight;
  doc.text(marginX, currentHeight, "Código: " + param.cliente.cliente_id || "");
  currentHeight += pdfConfig.subLineHeight;
  doc.text(marginX, currentHeight, "Cliente: " + param.cliente.cliente || "");
  currentHeight += pdfConfig.subLineHeight;
  doc.text(marginX, currentHeight, "Fecha de Lectura: " + param.fecha || "");
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;

 if (param.firma !== undefined) {
    doc.addImage(buildUrl('/file', {id: param.firma}), "PNG", marginX, currentHeight,90,14,"signature","NONE",0);
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(pdfConfig.subHeaderTextSize);
    doc.text(docWidth / 4 + 8, currentHeight, "Recibido Por");
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;
  
    doc.setFontSize(pdfConfig.labelTextSize);
  
    doc.text(docWidth / 4 + 8, currentHeight, param.cliente.cedula || "");
    currentHeight += pdfConfig.subLineHeight;
    doc.line(marginX, currentHeight, docWidth - marginX, currentHeight);
    currentHeight += pdfConfig.subLineHeight;
  
    doc.setFontSize(pdfConfig.subHeaderTextSize);
    doc.text(docWidth / 4 + 10, currentHeight, "Cédula");
  }

  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  currentHeight += pdfConfig.subLineHeight;
  doc.text(marginX, currentHeight, "Atendido Por: " + param.usuario || "");

  return doc;
}

export default conduce;
