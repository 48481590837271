import React, { useEffect, useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { postFile, buildUrl } from '../../../../services';
import './signature.css';

const processFile = (loadFile, scope, schema, file) => {
  loadFile({ ...file, loading: true });

  postFile(schema, scope, file.content, file.name).then(result => {
    if (result?.file) {
      loadFile(_file => ({ ..._file, loading: false, id: result.file }));
    } else {
      loadFile(_file => ({ ..._file, loading: false, error: "No se pudo cargar el archivo (ID inválido)" }));
    }
  }).catch(() => {
    loadFile(_file => ({ ..._file, loading: false, error: "No se pudo cargar el archivo" }));
  });

};

const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64.split(",")[1]); // Decodificar la parte Base64
  const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

const SignaturePad = ({
  label,
  path,
  data,
  setNewVal,
  schema,
  scope,
}) => {
  const _id = `input${path.replace(/(\.\.)|([._-]+)/g, "")}`;
  const [sign, setSign] = useState(null);
  const [file, setFile] = useState({ id: data });
  const [isSignaturePadVisible, setSignaturePadVisible] = useState(true);
  const containerRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const resizeCanvas = () => {
    if (containerRef.current) {
      const width = containerRef.current.offsetWidth;
      const height = 200; // Altura fija o dinámica según tus necesidades
      setCanvasSize({ width, height });
    }
  };
  const fileId = file.id
  useEffect(() => { if (fileId && fileId !== data) setNewVal(fileId) }, [data, setNewVal, fileId])

  useEffect(() => {
    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);
    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  const handleClear = () => {
    if (sign) {
      sign.clear();
    }
    setSignaturePadVisible(true);
  };

  const handleGenerate = () => {
    if (sign) {
      const imageUrl = sign.getCanvas().toDataURL("image/png");
      const fileBlob = base64ToBlob(imageUrl, "image/png");
      const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
      const fileName = `firma_${timestamp}.png`;

      const file = {
        name: fileName,
        size: fileBlob.size,
        content: fileBlob,
        url: imageUrl
      };

      processFile(setFile, scope, schema, file);
      setSignaturePadVisible(false);
    }
  };

  return (
    <div ref={containerRef} className="signature-container">
      <label htmlFor={_id} className="signature-label">
        {label}
      </label>

      {isSignaturePadVisible
        ? (
          <>
            <SignatureCanvas
              id={_id}
              ref={setSign}
              penColor="blue"
              backgroundColor="rgba(255, 255, 255, 0)"
              canvasProps={{
                className: "signature canvas",
                width: canvasSize.width,
                height: canvasSize.height,
                style: { border: "2px solid black", borderRadius: "8px" },
              }}
            />
            <div className="button-canvas-container">
              <button onClick={handleClear} className="btn btn-outline-secondary mx-1">
                Limpiar
              </button>
              <button onClick={handleGenerate} className="btn btn-outline-primary mx-1">
                Confirmar
              </button>
            </div>
          </>
        ) : file.error ? <p style={{ color: "red" }}>Error: {file.error}</p>
          : file.id ? <div className="signature p-2 "><img src={buildUrl('/file', { id: file.id }, scope)} alt={'Firma'} style={{ maxWidth: "100%" }} /></div>
          : <div><p>Cargando...</p></div>
      }


    </div>
  );
};

export default SignaturePad;
