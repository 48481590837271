import React, { useEffect, useState } from 'react';
import templateList from './template';
import { apiFetch } from '../../services';
import Modal from 'react-modal'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import pdfobject from 'pdfobject'

Modal.setAppElement("#root")

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    zIndex: "1500"
  },
  content: {
    top: '15%',
    bottom: 'auto',
    margin: 'auto',
    padding: '0px',
    minHeight: "400px",
    maxHeight: "80%"
    //transform: 'translate(-40%, -25%)'
  }
};


const Print = props => {
  const [docurl, setDocUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [maximized, setMaximized] = useState(props.maximize || false);

  const [data, setData] = useState(null);

  const document = props.document
  const scope = props?.context?.scope?.current

  useEffect(() => {
    //carga el documento.
    //console.log("doc changed", document)
    if (document) {
      apiFetch("/exec", {
        method: "print",
        d: document
      }, scope)
        .then(result => {
          if (result?.documento instanceof Array) setData(result.documento[0]);
        });
    } else setData(null);
  }, [document, scope])

  useEffect(() => {
    if (props.data && data == null) {
      //console.log("print started", props.data)
      setData({
        plantilla: props.plantilla,
        version: props.version,
        archivo: props.archivo,
        action: props.action,
        data: props.data instanceof Array ? props.data : [props.data]
      })
      props.dispatch({ type: "set-data", path: "print", overwrite: true, payload: null });
    }
  }, [data, props])

  useEffect(() => {
    //prepara el pdf.
    //console.log("print loading", data)
    if (!data) return;

    let handler = templateList[data.plantilla]

    if (handler instanceof Array) handler = handler[data.version || 0];

    if (!(handler instanceof Function)) return;

    let doc = handler(data.data[0]);
    if (!doc) return;

    const action = props.action || data.action || 'view'

    if (action == "save") doc.save(data.archivo || "documento.pdf")
    else {
      let blob = new Blob([doc.output('blob')], { type: "application/pdf" })
      let url = URL.createObjectURL(blob)

      if (action == 'print') window.open(url, 'portal_document').print(); //if it's a with-printer device, the print method is not necesary.
      else {
        //showing as modal
        setDocUrl(url); 
        setOpen(true);
      }
    }
    setData(null);
    props.dispatch({ type: "set-data", path: "print", overwrite: true, payload: null });
  }, [data, props.action, props.dispatch]);

  const afterOpenModal = () => {
    //console.log("cargando pdf en modal", docurl)
    pdfobject.embed(docurl, "#pdfdocument", {
      fallbackLink: "<p>Este navegador no soporta la visualización de archivos PDF. Puede descargar el archivo desde <a href='[url]'>este enlace</a>.</p>",
      PDFJS_URL: "/pdfjs/web/viewer.html",
      //forcePDFJS: true,
      pdfOpenParams: { view: 'FitV' }
    })
  }

  const closeModal = () => {
    setOpen(false);
    setDocUrl(null);
  }

  return (
    <Modal
      className="col-11 col-sm-9 col-md-8 col-lg-6 col-xl-5 m-auto"
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={"Documento PDF"}
      shouldCloseOnOverlayClick={true}
    >
      <div className="card rounded-0 m-0">
        <div className="card-header">
          <h5 className="card-title">{"Documento"}</h5>
          <div className="card-tools">
            <button type="button" className="btn btn-tool" data-card-widget="maximize" onClick={() => setMaximized(!maximized)} ><FA icon={maximized ? "compress-alt" : "expand-alt"} /></button>
            <button type="button" className="btn btn-tool" onClick={closeModal} ><FA icon="times" /></button>
          </div>
        </div>
        <div id="pdfdocument" style={{height: "70vh"} } className="card-body p-0 m-0" />
      </div>
    </Modal>
   )
}
export default Print;

