import { jsPDF } from "jspdf";
import React from 'react'
import { drawBarcode } from "./drawBarcode";

/**
 *
 * @param { {
 *   codigo_carpeta: string
 * } props
 *  
 */


const Carpeta = (props) => {

    const param = (Array.isArray(props.data)) ? props.data[0] : props;

    const splitTextAndGetHeight = (text, size) => {
        var lines = doc.splitTextToSize(text, size);
        return {
            text: lines,
            height: doc.getTextDimensions(lines).h,
        };
    };

    let titleBarCode = (typeof param.barcode !== 'undefined') ? param.barcode : '0000-Default';

    const options = {
        format: [110, 160],
        orientation: 'l'
    };

    var doc = new jsPDF(options);

    var docWidth = doc.internal.pageSize.width;
    var docHeight = doc.internal.pageSize.height;

    var colorBlack = "#000000";
    var colorGray = "#4d4e53";
    //starting at 15mm
    var currentHeight = 15;

    var marginX = 5

    //var startPointRectPanel1 = currentHeight + 6;

    var pdfConfig = {
        headerTextSize: 44,
        subHeaderTextSize: 20,
        labelTextSize: 18,
        fieldTextSize: 12,
        lineHeight: 6,
        subLineHeight: 4,
    };

    doc.setFontSize(pdfConfig.headerTextSize);
    doc.setTextColor(colorBlack);

    doc.text(marginX, currentHeight, param.empresa || "");
    currentHeight += pdfConfig.subLineHeight;
    currentHeight += pdfConfig.subLineHeight;

    doc.setFontSize(pdfConfig.headerTextSize);
    doc.text(titleBarCode, 2, 19, { align: "left", lang: "es" })

    currentHeight += pdfConfig.subLineHeight;

    drawBarcode(titleBarCode, { width: 1.5, height: 25 }, doc);

    let startPosition = 70;

    param.metadata.forEach(function (item) {
        let label = `${item.value}`;
        doc.setFontSize(pdfConfig.labelTextSize);
        doc.text(label, docWidth / 2, startPosition, { align: "center", lang: "es" })
        startPosition = startPosition + 7;
    })

    return doc;
}

export default Carpeta;