import React, { useMemo, useEffect } from 'react'
import SimpleLabel from './simplelabel'
import { asBool } from './utils'
import Select from 'react-select'
import { FixedSizeList as List } from "react-window";
import { useCallback } from 'react'
import { useState } from 'react'
import * as isEqual from 'react-fast-compare';

const noop = () => { };

const customStyles = {
	menu: (provided) => ({
		...provided,
		zIndex: 200
	}),
	option: (provided) => ({
		...provided,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		fontSize: '0.875rem',
		padding: '4px 8px',
	}),
	control: (provided) => ({
		...provided,
		borderRadius: '0.2rem',
		minHeight: '31px',
		boxShadow: null,
		fontSize: '0.875rem'
	}),

	valueContainer: (provided) => ({
		...provided,
		minHeight: '31px',
		padding: '1px 0.5rem'
	}),

	input: (provided) => ({
		...provided,
		margin: '0px',
	}),
	
	indicatorsContainer: (provided) => ({
		...provided,
		minHeight: '31px',
	}),

	clearIndicator: (provided) => ({
		...provided,
		padding: '0px 4px',
	}),
	loadingIndicator: (provided) => ({
		...provided,
		padding: '0px 4px',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: '0px 4px',
	}),

}

const height = 30;

const MenuList = (props) => {
	const { options, children, getValue, isMulti } = props;
	const [value] = getValue();
	let initialOffset = 0

	let currentHeight = children?.length ? (children.length * height) : height;
	if (currentHeight > 300) {
		currentHeight = 300
		if (!isMulti) initialOffset = options.indexOf(value) * height - 120
		initialOffset = initialOffset < 0 ? 0 : initialOffset
	}

	return (
		<List
			height={currentHeight}
			itemCount={children.length || 0}
			itemSize={height}
			initialScrollOffset={initialOffset}
		>
			{({ index, style }) => <div style={style}>{children[index]}</div>}
		</List>
	);
}


const CustomSelect = ({
	name,
	label,
	placeholder,
	value: defaultValue,
	path,
	data,
	setNewVal,
	select_multi,
	select_lookup,
	readonly,
	option,
	required
}) => {

	const _id = `input${path.replace(/(\.\d+\.)|([._-]+)/g, '')}${name}`
	const disable = (asBool(readonly) ? { disabled: true, isDisabled: true } : null)
	const isMulti = asBool(select_multi)
	const isLookup = asBool(select_lookup)
	//const option = useOptionList({ options, global: pageData, local: localData, label_path: option_label_path, value_path: option_value_path, name })

	if (asBool(required)) required = true;

	const current = useMemo(() => (
		(data || defaultValue || "")
			.split(";")
			.filter(e => !!e)
			.map(val => option.find(item => item.value === val))
			.filter(e => !!e)
	), [defaultValue, option, data])

	const [lookupKeys, setLookupKeys] = useState(undefined);

	const onChange = useCallback(val => {
		if ((val instanceof Array) && val.length > 0) setNewVal(val.map(elm => elm.value).join(";")) //many vals
		else setNewVal(val ? val.value : undefined)
	}, [setNewVal])

	const isLoading = !option.length

	useEffect(() => {
		//console.log("¿changed?", current, data, isLookup, isLoading, lookupKeys, path)
		if (isLoading) return;

		if (isLookup) {
			let val = current
			const prePath = path.split(".");
			prePath.pop();

			if ((val instanceof Array)) val = val[0];

			const { label, ...item } = val || {};

			const oldkeys = [...(lookupKeys || [])];
			const newkeys = Object.keys(item);

			if (!isEqual(newkeys, lookupKeys)) setLookupKeys(newkeys);

			newkeys.forEach(k => {
				const idx = oldkeys.indexOf(k);
				if (idx !== -1) oldkeys.splice(idx, 1);

				if (k === "value") data === item[k] ? setNewVal(item[k]) : noop();
				else setNewVal(item[k], [...prePath, k].join("."))
			})

			oldkeys.forEach(k => (k === "value") ? (data ? setNewVal(undefined) : noop() ) : setNewVal(undefined, [...prePath, k].join(".")))
		}
		else onChange(current)
	}, [isLoading, current, onChange, data, isLookup, lookupKeys, path, setNewVal])


	console.log("required", required, _id)
	return (
		<React.Fragment>
			<SimpleLabel label={label} htmlFor={_id} />
			<Select id={_id}
				className="basic-single"
				classNamePrefix="select"
				value={current || ''}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				isClearable={true}
				isSearchable={true}
				isLoading={!option.length}
				name={name}
				{...disable}
				placeholder={placeholder}
				onChange={onChange}
				options={option}
				components={{ MenuList }}
				styles={customStyles}
			/>
			{required && (
				<input
					tabIndex={-1}
					autoComplete="off"
					style={{ opacity: 0, height: 0, position: "absolute" }}
					value={current}
					required={required}
				/>
			)}
		</React.Fragment>
	)
}

export default CustomSelect;
