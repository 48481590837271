import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useReducer } from 'react'
import { useDropzone } from 'react-dropzone'
import { buildUrl, postFile } from '../../../../services'
import SimpleLabel from './simplelabel'

const fileLoader = (state = [], payload = {}) => {
  let newfile = payload.file;
  console.log(payload)

  switch (payload.action) {
    case "set":
      const idx = state.findIndex(file => !newfile.name ? (file.id === newfile.id) : (file.name === newfile.name))
      if (newfile.delete || newfile.del) newfile = undefined;

      if (idx === -1) return [...state, newfile].filter(e => !!e);
      state.splice(idx, 1, newfile);
      return [...state].filter(e => !!e)
    case "synced-form":
      return state.map(({ created, ...file }) => file )
    default:
      console.warn("Unknown file load action:", payload.action);
      return [...state];
  }
  
}

const newFiles = (loadFile, scope, schema, ref) => files => files.forEach(file => { 

  loadFile({
    name: file.name,
    size: file.size,
    loading: true,
  });
  postFile(schema, scope, file, file.name, ref).then(result => {
      const id = result && result.file && Array.isArray(result?.file) ? result.file[0]?.id : result?.file

    loadFile({
      name: file.name,
      size: file.size,
      error: id ? undefined : "No se pudo cargar el archivo",
      id: id,
      created: !!id
    })
  }, reason => {
    //console.log("error loading file", file.name, reason);
    loadFile({
      name: file.name,
      size: file.size,
      error: "No se pudo cargar el archivo",
    })
  });
})

const FileInput = ({ name, label, scope, placeholder, path, data, setNewVal, max = 1, schema, accept: contentType }) => {
  const _id = `input${path.replace(/(\.\.)|([._-]+)/g, '')}${name}` 
  const _data = (data instanceof Array) ? data.filter(e=>!!e) : []
  const [files, loadFile] = useReducer(fileLoader, _data);

  const onDropAccepted = useCallback(fileDroped => newFiles(file => loadFile({ action: "set", file }), scope, schema, _id)(fileDroped), [scope, schema, _id])

  const removeImage = (name) => {
    const newdata = files.filter(file => !!file.id && file.name !== name).map(({ name, id }) => ({ id, name }))
    loadFile({ action: "set", file: { name, delete: true } })
    setNewVal(newdata);
  }
  const accept = contentType.replace(";", ",");
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    name,
    accept,
    multiple: max > 1,
    maxFiles: max
  })

  const newdata = files.filter(file => !!file.id).map(({ name, id }) => ({ id, name }))

  const filesToFormData = newdata.some(file => !!files.find( item => item.id === file.id).created )
  const filesToDelete = files.filter(file => file.id && !file.created && !_data.find(dataFile => dataFile.id === file.id)).map(file => file.id)
  const filesToAdd = _data.filter(file => !files.find(dataFile => dataFile.id === file.id ))
  
  useEffect(() => {
    if (filesToFormData) {
      //console.log("synced files")
      loadFile({ action: "synced-form" });
      setNewVal(newdata);
    } else if (filesToDelete.length) {
      //console.log("deleting files", filesToDelete)
      filesToDelete.forEach(id => {
        loadFile({ action: "set", file: { id, delete: true } });
      })
    } else if (filesToAdd.length) {
      //console.log("adding files", filesToAdd)
      filesToAdd.forEach(file => {
        loadFile({ action: "set", file });
      })
    }
  }, [filesToFormData, filesToDelete, filesToAdd, newdata, setNewVal])

  const fileList = files.map(({ name, error, id, loading }) => (
    <li key={name} style={{ borderRadius: "10px", display: "inline-block", backgroundColor: "hsl(0,0%,90%)" }} className="mr-1 mb-1 pr-2" >
      {error && <FontAwesomeIcon className="mx-1" icon={["fas", "exclamation-circle"]} color="#bf4040" title={error} />}
      {loading && <FontAwesomeIcon className="mx-1" icon={["fas", "circle-notch"]} spin title={"Cargando..."} />}
      {/*{id && <FontAwesomeIcon className="mr-1" icon={["fas", "check-circle"]} color="green" title={id} />}*/}
      {id && < img src={buildUrl('/file', { id }, scope)} className="mr-1 rounded" height="32px" alt="Preview" />}
      <span>{name}</span>
      <span style={{ cursor: "pointer" }} onClick={() => removeImage(name)} > <FontAwesomeIcon className="ml-1" icon={["far", "times-circle"]} title={"Eliminar"} /></span>
    </li>
  ));

  return (
    <React.Fragment>
      <SimpleLabel label={label} htmlFor={_id} />
      {
        files.length < max
          ? <div {...getRootProps({
            id: _id,
            style: {
              border: "#ced4da dashed 2px",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              borderRadius: "2px",
              padding: "8px",
              alignItems: "center",
              backgroundColor: "#fafafa",
              color: "#bdbdbd"
            }
          })}>
            <input {...getInputProps({ className: "form-control form-control-sm text-weight-normal" })} />
            {
              isDragActive
                ? <p className="my-1" >Suelte los archivos aquí...</p>
                : <p className="my-1" >Arrastre archivos aquí o haga clic para seleccionar.</p>
            }
            {placeholder && <p className="mt-0 mb-1 font-italic" >{placeholder}</p>}
          </div>
          : null
      }
      <aside>
        <ul id={_id + "list"} className="p-0 mt-1">
          {fileList}
        </ul>
      </aside>

    </React.Fragment>
  )
}


export default FileInput;
